<script setup>
import { JShowCase } from 'media-flow-ui'

//banner start
import { toRefs, ref } from 'vue'
import { usePageBuilder } from '~/stores/page-builder'

import StaticBanner from '~~/models/layout/components/banner/StaticBanner'
import DynamicBanner from '~~/models/layout/components/banner/DynamicBanner'
import BannerStyle from '~~/models/layout/components/banner/BannerStyle'
import { $$t } from '~~/utils/i19m'

import { storeToRefs } from 'pinia'

import Card from '~~/models/layout/components/content/Card'
import TabData from '~~/models/layout/tabs/TabData'
import { formatPadding, formatWidth } from '~/models/layout/style'
import Font from '~/models/font/Font'
import AnalyticsHandler from '~/models/analytics/handler/Handler'
//grid end

const pageBuilderStore = usePageBuilder()

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
  load: {
    type: Boolean,
    default: true,
    required: false,
  },
  rowOrder: {
    type: Number,
    required: false,
    default: null
  }
})

const bannerData = ref({})
const bannerStyle = ref(new BannerStyle())

const analyticsHandler = ref(null)
const vpAppsShowCase = ref(null)

const { data, load } = toRefs(props)

// await  useLazyAsyncData
// await useAsyncData(async () => {
const style = new BannerStyle()
style.fromQuery(data.value.banner?.style)
bannerStyle.value = style

if (data.value.banner.props.isDynamic) {
  const queryId = data.value.banner.props.settings?.query.id
  const contentId =
    data.value.banner.props.settings?.contentId ||
    data.value.banner.props.settings?.groupInstanceId
  const params = {
    take: 1, //data.value.mediaNumber, //1
  }

  const banner = new DynamicBanner()

  let response = null

  try {
    res = await pageBuilderStore.actionGetQueryDataInitClient(
      queryId,
      params,
      contentId,
      null,
      props.rowOrder
    )
  } catch (error) {
    console.error(error)
  }

  if (res) {
    response = res.response
  }

  if (response) banner.fromQuery(response.data?.[0], data.value.banner.props)

  bannerData.value = banner
} else {
  const banner = new StaticBanner()
  banner.fromQuery(data.value.banner)

  bannerData.value = banner
}

//GRID --------------------------------------------------------------------------------

const { deviceType } = storeToRefs(pageBuilderStore)

const isLoading = ref(true)
const widgetData = ref([])
// const { load } = toRefs(props);
const numberOfColumns = ref(6)
const mediaNumber = ref(0)

const limit = ref(4)

if (data.value.grid.mediaNumber) {
  limit.value = data.value.grid.mediaNumber
} else if (data.value.grid.props.mediaNumber) {
  limit.value = data.value.grid.props.mediaNumber
}

//skeleton fill with default
widgetData.value = [] //Array(limit.value).fill({})

//to change response
if (data.value.grid?.columnsNumber) {
  numberOfColumns.value = data.value.grid.columnsNumber
} else if (data.value.grid.props?.columnsNumber) {
  numberOfColumns.value = data.value.grid.props.columnsNumber
}

const dataa = ref(props.data)

if (data.value.grid.props?.query?.id) {
  const tabData = new TabData()
  //set layout data foreach tab static ore tab dynamic(tabs)
  tabData.setTabLayout(data.value.grid)

  dataa.value = tabData
}

const hasPagination = dataa.value.props.infiniteScroll

const getImageByAspectRatio =
  dataa.value?.cardStyle && dataa.value?.cardStyle[deviceType.value]
    ? dataa.value?.cardStyle[deviceType.value].imageStyle?.aspectRatioProperty
    : null

const isStatic = dataa.value?.cardStyle?.[deviceType.value]?.isStatic

const skip = ref(0)
const take = ref(
  data.value.grid.props?.mediaNumber <= 4
    ? data.value.grid.props.mediaNumber
    : 4
) //ref(dataa.value.mediaNumber);
const total = ref(null)

mediaNumber.value = data.value.grid?.props?.mediaNumber
numberOfColumns.value = data.value.grid.props.columnsNumber

const queryId = dataa.value.props.queryId
const contentId = dataa.value.contentId
const sortingMethod = ref(data.value.grid.props?.sortingMethod ?? null)
const sortingMethods = ref(data.value.grid.props?.sortingMethods ?? [])

const gridWrapperStyle = computed(() => {
  const tempWidth =
    typeof data.value.style.gridWidth === 'number'
      ? { value: data.value.style.gridWidth, type: 'px' }
      : data.value.style.gridWidth

  return { padding: dataa.value.style?.padding, width: formatWidth(tempWidth) }
})

sortingMethods.value = sortingMethods.value.map((method) => {
  method.name = $$t(method.name)
  return method
})

await useAsyncData(async () => {
  const params = {
    take: take.value,
    skip: skip.value,
  }

  if (sortingMethod.value) {
    params.sort = sortingMethod.value
    params.defaultSort = sortingMethod.value
  }

  let response
  try {


    const res = await pageBuilderStore.actionGetQueryDataInitClient(
      queryId,
      params,
      contentId,
      false,
      props.rowOrder
    )

    if (res) {
      response = res.response
    }

    total.value = response?.total
    skip.value += take.value
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }

  widgetData.value = response.data.map((rawItem) => {
    const item = new Card()

    try {
      item.fromQuery(rawItem, getImageByAspectRatio, isStatic)
    } catch (error) {
      console.error('out', error)
    }

    return item
  })
})

const showCaseTitleStyle = computed(() => {
  const titlePadding = formatPadding(data.value.style?.padding)

  const titleFontStyle = new Font()
  if (data.value.style?.titleStyle) {
    titleFontStyle.fromData(data.value.style?.titleStyle)
  }

  return {
    ...titlePadding,
    ...titleFontStyle,
  }
})

const getAnalyticsContent = (event) => {
  const { img, title, description, videoSrc } = bannerData.value
  const gridData = widgetData.value.map(card => {
    return {
      id: card.id,
      title: card.title,
      img: card.img,
      url: card.url,
      author: card.author
    }
  })

  const content = {
    title,
    description,
    media: img || videoSrc || '',
    rowIndex: props.rowOrder,
    event: event,
    gridData: gridData
  }

  return content
}

const onShowCaseClick = () => {
  const content = getAnalyticsContent('BannerClick')

  analyticsHandler.value.sendShowCaseClick(content)
}

const onShowCaseView = () => {
  const content = getAnalyticsContent('BannerView')

  analyticsHandler.value.sendShowCaseView(content)
}

onMounted(() => {
  analyticsHandler.value = new AnalyticsHandler() //AnalyticsHandler()

  useIsElementOnView(vpAppsShowCase.value, onShowCaseView, true)
})

</script>
<template>
  <div class="my-5" ref="vpAppsShowCase" @click="onShowCaseClick">
    <!-- <pre>
  {{ widgetData }}
</pre> -->
    <JShowCase :layoutType="data.props.layoutType" :title="data.props.title" :title-style="showCaseTitleStyle" :banner="{
      data: bannerData,
      customWrapper: defineNuxtLink({}),
      hasOverlay: bannerStyle?.imageStyle?.blur > 0 ? true : false,
      titleStyle: bannerStyle.titleStyle,
      descriptionStyle: bannerStyle.descriptionStyle,
      contentStyle: bannerStyle.contentStyle,
      wrapperStyle: bannerStyle.wrapperStyle,
      contentClasses: bannerStyle.contentClasses,
      buttons: bannerData.buttons,
      autoplay: bannerData.autostart,
      canControlAudio: bannerData.canControlAudio,
    }" :grid="{
      data: widgetData,
      widgetIdentifier: dataa.contentId || dataa.id,
      wrapperStyle: gridWrapperStyle,
      numberOfColumns: limit,
      contentWrapperStyle: { gap: dataa.style?.gap },
      cardStyle: dataa.cardStyle ? dataa.cardStyle[deviceType] : {},
      breakpoint: deviceType,
    }" :new-item-text="$$t('component_card_new_text')" class="button-cursor">
    </JShowCase>
  </div>
</template>

<style lang="scss" scoped>
::v-deep(.button-cursor) {
  .button-text {
    cursor: pointer !important;
  }
}
</style>
